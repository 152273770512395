export const m = {
  // 简体中文语言包
  app: {
    login: '登录',
    'Home': '首页',
    'Nearby': '周边',
    'Message': '消息',
    'Mypage': '我的',
  },
  common: {
    // 暂无数据
    'NoData': '暂无数据',
    // 敬请期待
    'ComingSoon': '敬请期待',
    // 请重新登录
    'PleaseLoginAgain': '请重新登录',
    // 登录已过期，请重新登录
    'LoginExpiredPleaseLoginAgain': '登录已过期，请重新登录',
    // 请在小程序中打开
    'PleaseOpenInMiniProgram': '请在小程序中打开',
    // 复制成功
    'copySuccess': '复制成功',
    // 复制失败
    'copyFail': '复制失败',
  },
  home: {
    'readMore': '阅读更多',
    'hotelEventOverview': '雪场活动概览',
    // 暂无公告
    'NoAnnouncement': '暂无公告',
    // 取消
    'Cancel': '取消',
    // 滑雪场介绍
    'SkiResortIntroduction': '滑雪场介绍',
    // 营业时间
    'BusinessHours': '营业时间',
    // 已收藏
    'Collected': '已收藏',
    // 已取消收藏
    'UnCollected': '已取消收藏',
    // 您已成功收藏雪场【${merchantName}】\n请在【我的】->【我的收藏】里查看收藏列表
    'CollectedSuccessfully': '您已成功收藏雪场【{merchantName}】\n请在【我的】->【我的收藏】里查看收藏列表',
    // 您已成功取消对雪场【${merchantName}】的收藏
    'UnCollectedSuccessfully': '您已成功取消对雪场【{merchantName}】的收藏',
  },
  accountHome: {
    welcome: '欢迎 🎉',
    Login: '登录',
    Register: '注册',
    ContinueAsAGuest: '以游客身份继续',
  },
  login: {
    'WelcomeBackGladToSeeYouAgain': '欢迎回来！很高兴再次见到你！',
    // Enter your email
    'EnterYourEmail': '请输入您的电子邮件',
    // Enter your password
    'EnterYourPassword': '请输入您的密码',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '请输入正确的邮箱地址',
    // Forgot Password?
    'ForgotPassword': '忘记密码？',
    // Don’t have an account?
    'DontHaveAnAccount': '没有帐号？',
    // Register Now
    'RegisterNow': '现在注册',
    // Login
    'Login': '登录',
  },
  register: {
    'WelcomeBackGladToSeeYouAgain': '欢迎回来！很高兴再次见到你！',
    // Nickname
    'Nickname': '昵称',
    // Email
    'Email': '请输入您的电子邮件',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '请输入正确的邮箱地址',
    // Password
    'Password': '请输入您的密码',
    // 两次输入的密码不一致
    'PasswordsAreInconsistent': '两次输入的密码不一致',
    // Confirm password
    'ConfirmPassword': '确认密码',
    // Agree and Register
    'AgreeAndRegister': '注册',
    // 发送验证码
    'SendCode': '发送验证码',
    // 验证
    'Verify': '验证',
    // 注册用户
    'RegisteredUsers': '注册成为新用户',
    // OTP Verification
    'OTPVerification': '验证码校验',
    // Enter the verification code we just sent on your email address.
    'EnterTheVerificationCodeWeJustSentOnYourEmailAddress': '请输入我们刚刚发送到您电子邮件地址的验证码。',
    // 请填写密码
    'PleaseEnterYourPassword': '请填写密码',
    // 将会作为您的登录密码
    'WillBeUsedAsYourLoginPassword': '将会作为您的登录密码',
    // RegisteredSuccessfully
    'RegisteredSuccessfully': '注册成功',
    // 请输入6-20位包含英数字的密码
    'PleaseEnterAPasswordOf6To20DigitsContainingEnglishAndNumbers': '请输入6-20位包含英数字的密码',
  },
  sso: {
    // Or Login with
    'OrLoginWith': '或登录',
  },
  forgotPassword: {
    // Forgot Password
    'ForgotPassword': '忘记密码？',
    // Don't worry! It occurs. Please enter the email address linked with your account.
    'DontWorryItOccursPleaseEnterTheEmailAddressLinkedWithYourAccount': '别担心！ 请输入与您的帐户关联的电子邮件地址。',
    // Enter your email
    'EnterYourEmail': '请输入您的电子邮件',
    // Send Code
    'SendCode': '发送验证码',
    // Remember Password?
    'RememberPassword': '记得密码？',
    // Login
    'Login': '登录',
    // OTP Verification
    'OTPVerification': '验证码校验',
    // Enter the verification code we just sent on your email address.
    'EnterTheVerificationCodeWeJustSentOnYourEmailAddress': '请输入我们刚刚发送到您电子邮件地址的验证码。',
    // Verify
    'Verify': '验证',
    // Didn’t received code?
    'DidntReceivedCode': '没有收到验证码？',
    // Resend
    'Resend': '重新发送',
    // Create new password
    'CreateNewPassword': '创建新密码',
    // Your new password must be unique from those previously used.
    'YourNewPasswordMustBeUniqueFromThosePreviouslyUsed': '您的新密码必须与以前使用的密码不同。',
    // New Password
    'NewPassword': '新密码',
    // Confirm Password
    'ConfirmPassword': '确认密码',
    // Reset Password
    'ResetPassword': '重置密码',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '请输入正确的邮箱地址',
    // 请输入密码
    'PleaseEnterYourPassword': '请输入密码',
    // 两次输入的密码不一致
    'PasswordsAreInconsistent': '两次输入的密码不一致',
    // 发送成功
    'SendSuccessfully': '发送成功',
    // 请输入6-20位包含英数字的密码
    'PleaseEnterAPasswordOf6To20DigitsContainingEnglishAndNumbers': '请输入6-20位包含英数字的密码',
  },
  passwordChanged: {
    // Password Changed!
    'PasswordChanged': '密码已更改！',
    // Your password has been changed successfully.
    'YourPasswordHasBeenChangedSuccessfully': '您的密码已成功更改。',
    // Back to Login
    'BackToLogin': '返回登录',
  },
  FeedBack: {
    // Name
    'Name': '姓名',
    // Please enter the English name on the passport!
    'PleaseEnterTheEnglishNameOnThePassport': '请输入护照上的英文名！',
    // Telphone
    'Telphone': '电话号码',
    // lease enter your phone number
    'LeaseEnterYourPhoneNumber': '请输入您的电话号码',
    // Email
    'Email': '电子邮箱',
    // Please enter your e-mail address
    'PleaseEnterYourEMailAddress': '请输入您的电子邮箱地址',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '请输入正确的邮箱地址',
    // Check-in 
    'CheckIn': '入住日期',
    // Please select a check-in date
    'PleaseSelectACheckInDate': '请选择入住日期',
    // Feedback content
    'FeedbackContent': '反馈内容',
    // 请输入反馈内容
    'PleaseEnterYourFeedbackContent': '请输入反馈内容',
    // 提交成功
    'SubmittedSuccessfully': '提交成功',
    // Please enter your comments and suggestions, and you can also contact the ski resort through here if the item is lost.
    'PleaseEnterYourCommentsAndSuggestionsAndYouCanAlsoContactTheHotelThroughHereIfTheItemIsLost': '请输入您的意见和建议，如果物品丢失，您也可以通过此处与雪场联系。',
    // Sent
    'Sent': '发送',
  },
  navigation: {
    // Save to album
    'SaveToAlbum': '保存',
    // Navigate to the ski resort
    'NavigateToTheHotel': '导航到雪场',
    // ※可长按保存
    'LongPressToSave': '※可长按保存',
    // 地铁路线
    'SubwayRoute': '地铁路线',
  },
  CouponFormat: {
    // See details
    'SeeDetails': '查看详情',
    // Get
    'Get': '领取',
    // Use
    'Use': '使用',
    // 永久有效
    'PermanentValidity': '永久有效',
    // 有效期至：${item.endTimeDisplay}
    'ValidUntil': '有效期至：{endTimeDisplay}',
    // 有效天数：${item.effeDays}天
    'ValidDays': '有效天数：{effeDays}天',
    // 结束时间：${item.endTimeDisplay}
    'EndTime': '结束时间：{endTimeDisplay}',
    // 使用时间：${item.useTimeDisplay}
    'UseTime': '使用时间：{useTimeDisplay}',
    // `每人限领：${item.limit}次`
    'Limit': '每人限领：{limit}次',
  },
  shopList: {
    // JPY
    'JPY': 'JPY',
    // RMB
    'RMB': 'RMB',
  },
  barCart: {
    // JPY
    'JPY': 'JPY',
    // Next
    'Next': '去结算',
  },
  userCenter: {
    // Coupon
    'Coupon': '优惠券',
    // Membership
    'Membership': '雪场会员',
    // Collection
    'Collection': '收藏',
    // My Order
    'MyOrder': '我的订单',
    // Coupon Center
    'CouponCenter': '优惠券中心',
    // Settings
    'Settings': '设置',
    // Service
    'Service': '服务',
    // Product review
    'ProductReview': '商品评价',
    // About QR SKI
    'AboutQRSKI': '关于QR SKI',
    // Logout
    'Logout': '退出登录',
    // 我的收藏
    'MyCollection': '我的收藏',
    // 我的优惠券
    'MyCoupon': '我的优惠券',
  },
  eventList: {
    // 查看详情
    'SeeDetails': '查看详情',
  },
  collectionList: {
    // 访问足迹
    'AccessFootprint': '访问足迹',
    // 我的收藏
    'MyCollection': '我的收藏',
    // 点击查看〉
    'ClickToView': '点击查看〉',
    // 确认
    'Confirm': '确认',
    // 确定要跳转吗？
    'ConfirmToJump': '确定要跳转吗？',
  },
  myCouponList: {
    // 可使用
    'Available': '可使用',
    // 已过期
    'Expired': '已过期',
    // 已使用
    'Used': '已使用',
    // 没有更多啦~
    'NoMore': '没有更多啦~',
    // 即将过期
    'Expiring': '即将过期',
    // 去使用
    'GoToUse': '去使用',
    // 领取
    'Get': '领取',
  },
  NotFound: {
    // 信息获取失败
    'HotelInformationFailedToGet': '信息获取失败',
    // 查看介绍
    'ViewIntroduction': '查看介绍',
  },
  NoOpen: {
    // 该雪场暂未开放
    'NoOpen': '该雪场暂未开放',
    // 查看介绍
    'ViewIntroduction': '查看介绍',
  },
  Article: {
    // 营业时间
    'BusinessHours': '营业时间',
    // 介绍
    'Introduction': '介绍',
  },
  DiscoverDetail: {
    // 问路卡
    'AskForTheWayCard': '问路卡',
    // 导航
    'Navigation': '导航',
    // 简介
    'Introduction': '简介',
    // 基本信息
    'BasicInformation': '基本信息',
    // 地址
    'Address': '地址',
    // 营业时间
    'BusinessHours': '营业时间',
    // 本页信息由第三方来源提供，不保证信息及时有效
    'FooterTip': '本页信息由第三方来源提供，不保证信息及时有效',
    // （人均消费）
    'AverageConsumption': '（人均消费）',
  },
  Coupon: {
    // 商家专属
    'MerchantExclusive': '商家专属',
    // 平台通用
    'PlatformGeneric': '平台通用',
    // 10%免税
    '10DutyFree': '10%免税',
    // 特定店铺
    'SpecificStore': '特定店铺',
    // 去使用
    'GoToUse': '去使用',
    // 去领取
    'GoToGet': '去领取',
    // 去查看
    'GoToView': '去查看',
  },
  CouponDetail: {
    // 10%免税
    '10DutyFree': '10%免税',
    // 优惠券详情
    'CouponDetail': '优惠券详情',
    // 特定店铺
    'SpecificStore': '特定店铺',
    // 使用方法
    'UseMethod': '使用方法',
    // 使用须知
    'UseNotice': '使用须知',
    // 本优惠券为第三方搜集，进店时请与店员做好沟通，本程序不承担连带责任
    'Disclaimer': '本优惠券为第三方搜集，进店时请与店员做好沟通，本程序不承担连带责任',
    // 已过期
    'Expired': '已过期',
    // 已使用
    'Used': '已使用',
    // 请让雪场服务人员点击，自己点击无效，敬请谅解
    'PleaseLetTheHotelStaffClickItYourself': '请让雪场服务人员点击，自己点击无效，敬请谅解',
    // 由服务人员使用
    'UsedByTheServiceStaff': '由服务人员使用',
    // 领取
    'Get': '领取',
    // 确认
    'Confirm': '确认',
    // 确认要使用此优惠券吗？
    'ConfirmToUseThisCoupon': '确认要使用此优惠券吗？',
    // 请登录
    'PleaseLogin': '请登录',
  },
  Cropper: {
    // 裁切
    'Crop': '裁切',
  },
  UserSetting: {
    // 请输入昵称
    'PleaseEnterYourNickname': '请输入昵称',
    // 手机号
    'PhoneNumber': '手机号',
    // 请输入您的手机号
    'PleaseEnterYourPhoneNumber': '请输入您的手机号',
    // 保存
    'Save': '保存',
  },
  AiChat: {
    // 觉得答案如何？
    'HowDoYouFeelAboutTheAnswer': '觉得答案如何？',
    // 感谢您的回馈！
    'ThankYouForYourFeedback': '感谢您的回馈！',
    // 按住 说话
    'PressAndSpeak': '按住 说话',
    // 发送
    'Send': '发送',
    // 松开 结束
    'ReleaseEnd': '松开 结束',
    // 最多支持${queryLimit.value}个字的发送
    'MaximumSupportForSendingWords': '最多支持{queryLimit}个字的发送',
    // 连接发生错误
    'ConnectionError': '连接发生错误',
    // 你好，我是${res.data.name}的小助手
    'HelloIAmTheAssistant': '你好，我是{name}的小助手',
    // ${merchatData.name}的AI小助手为您服务
    'AIAssistantForMerchat': '{name}的AI小助手为您服务',
    // 无法查询到设施信息，请重新扫码
    'UnableToQueryFacilityInformationPleaseScanAgain': '无法查询到设施信息，请重新扫码',
    // 该设施已暂停咨询服务，请重新扫码
    'TheFacilityHasSuspendedConsultingServicesPleaseScanAgain': '该设施已暂停咨询服务，请重新扫码',
    // 查询设施信息失败，请稍后再试
    'FailedToQueryFacilityInformationPleaseTryAgainLater': '查询设施信息失败，请稍后再试',
    // 以下是一些常见问题，请点击您想问的问题，确认答案。
    'TheFollowingAreSomeCommonQuestionsPleaseClickOnTheQuestionYouWantToAskToConfirmTheAnswer': '以下是一些常见问题，请点击您想问的问题，确认答案。',
    // 语音识别初始化失败，请检查麦克风权限
    'VoiceRecognitionInitializationFailedPleaseCheckTheMicrophonePermission': '语音识别初始化失败，请检查麦克风权限',
    // 很抱歉，可以更详细的描述您的问题吗？
    'SorryCanYouDescribeYourProblemInMoreDetail': '很抱歉，可以更详细的描述您的问题吗？',
  },
  TranslationHelper: {
    // 说中文
    'SayLang': '中文',
    // 说日语
    'SayJp': '日语',
    // 说英语
    'SayEn': '英语',
    // 按住 说话
    'PressAndSpeak': '按住 说话',
    // 松开 结束
    'ReleaseEnd': '松开 结束',
    // 语音识别初始化失败，请检查麦克风权限
    'VoiceRecognitionInitializationFailedPleaseCheckTheMicrophonePermission': '语音识别初始化失败，请检查麦克风权限',
    // 通过下方工具栏，输入文字或语音，即可进行翻译
    'EnterTextOrVoice': '通过下方工具栏，输入文字或语音，即可进行翻译',
    // 发送过于频繁，请稍后再试
    'SendTooFrequentlyPleaseTryAgainLater': '发送过于频繁，请稍后再试',
    // 连接失败，请稍后重试
    'ConnectionFailedPleaseTryAgainLater': '连接失败，请稍后重试',
  },
  gelande: {
    // 全部区域
    'AllAreas': '全部区域',
    // 全部状况
    'AllConditions': '全部状况',
  },
  ErrorPage: {
    // 该内容不支持当前语言
    '406': '该内容不支持当前语言',
    // 发生错误，请稍后再试
    '500': '发生错误，请稍后再试',
  }
};