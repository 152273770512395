import { createAPI } from './create-api'
import auth from './module/auth'
import store from '@/store'
import router from "@/router";
import { showLoading, hideLoading, getLang, setLastRoute } from "../utils/utils";
import { showFailToast } from 'vant'
import i18n from "@/lang";
import { showDialog } from 'vant';



const userApiBaseUrl = process.env.VUE_APP_API_BASE_URL;

const api = createAPI({ baseURL: userApiBaseUrl + '/web' })
api.interceptors.request.use(
  (config) => {
    const data = config.data || {};
    // 应对弱化登录，不再使用shouldJWT
    // let shouldJWT = config.shouldJWT || data.shouldJWT;
    if (store.getters.token) {
      config.headers["X-Auth-TokenBody"] = `${store.getters.token}`;
    }
    // 应对弱化登录，不再使用shouldAiJWT
    // let shouldAiJWT = config.shouldAiJWT || data.shouldAiJWT;
    if (store.getters.token) {
      config.headers["Authorization"] = `Bearer ${store.getters.token}`;
    }

    // 获取本地缓存的语言
    const locale = i18n.global.locale.value
    config.headers['Accept-Language'] = locale
    if (data.shouldJWT !== undefined) {
      delete config.data.shouldJWT;
    }
    const noLoading = config.noLoading;
    console.log(config);
    if (!noLoading) {
      showLoading();
    }
    return config;
  },
  (error) => {
    const status = error?.response?.status;
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);

//响应拦截器
let goLoginFlagTimer = null;
api.interceptors.response.use(
  (response) => {
    console.log(router.currentRoute.value.name)
    const config = response.config;
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return response;
  },
  async (error) => {
    const status = error?.response?.status;
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    const silent = config.silent;
    let routeId = router.currentRoute.value.params.id || null;
    // 如果不存在id，就从缓存中取
    if (!routeId) {
      routeId = localStorage.getItem('merchantId') || null
    }
    const isAiApi = config.isAiApi
    const isAzureApi = config.isAzureApi
    if (!noLoading) {
      hideLoading();
    }
    if (!silent) {
      if (isAiApi) {
        // ai相关接口
        let errorList = error?.response?.data;
        // TODO: 兼容老版400错误
        const message = error?.response?.data?.message;
        if (message) {
          errorList = [message]
        }

        if (typeof errorList === "object" && errorList?.length > 0) {
          // 将errorList转换成字符串，换行符分割
          showDialog({ message: errorList.join('\n'), confirmButtonColor: '#1cb3d3' });
        }
      } else if (isAzureApi) {
        // azure相关接口
      } else {
        switch (status) {
          case 400:
            const errorList = error?.response?.data;
            if (typeof errorList === "object") {
              errorList.forEach((errorMessage) => {
                showFailToast(errorMessage)
              });
            }
            break;
          case 403:
            break;
          case 401:
            const t = i18n.global.t
            const routeName = router.currentRoute.value.name;
            const shouldLogin = router.currentRoute.value.meta.shouldLogin;
            store.dispatch('setToken', '')
            localStorage.removeItem('token')
            if (routeName !== "Login") {
              clearTimeout(goLoginFlagTimer);
              goLoginFlagTimer = setTimeout(() => {
                if (!routeId) {
                  // 如果没有id，就跳转到not-found
                  router.replace({ name: "NotFound" });
                } else if (shouldLogin) {
                  showFailToast(t('m.common.LoginExpiredPleaseLoginAgain'))
                  setLastRoute(router.currentRoute.value);
                  router.replace({ name: "Login", params: { id: routeId } });
                }

              }, 200);
            } else {
              const errorList = error?.response?.data;
              if (typeof errorList === "object") {
                errorList.forEach((errorMessage) => {
                  showFailToast(errorMessage)
                });
              }
            }
            break;
          // 404
          case 404:
            clearTimeout(goLoginFlagTimer);
            goLoginFlagTimer = setTimeout(() => {
              router.replace({ name: "NotFound" });
            }, 200);
            break;
          // 406
          case 406:
            clearTimeout(goLoginFlagTimer);
            goLoginFlagTimer = setTimeout(() => {
              // 检查路由ID是否存在以及雪场是数据是否存在
              if (routeId && store.getters.merchant) {
                // 如果两个条件都为真，则用给定的ID和错误代码替换当前路由为错误路由
                router.replace({ name: "Error", params: { id: routeId, code: 406 } });
              } else {
                router.replace({ name: "ErrorNoMerchantId", params: { code: 406 } });
              }
            }, 200);
            break;
          case 503:
          case 500:
          case 501:
          case 502:
          case 504:
          case undefined:
            clearTimeout(goLoginFlagTimer);
            goLoginFlagTimer = setTimeout(() => {
              if (routeId && store.getters.merchant) {
                router.replace({ name: "Error", params: { id: routeId, code: 500 } });
              } else {
                router.replace({ name: "ErrorNoMerchantId", params: { code: 500 } });
              }
            }, 200);
            break;
          default:
            break;
        }

      }
    }

    return Promise.reject(error);
  }
);

export const authApi = auth(api)
