<template>
  <div
    v-html="sanitizedHTML"
    @click="onClickHtml"
    class="rich-text-content"
    ref="richTextContent"
  ></div>
</template>
<script>
import { formatImgSrc, onClickHtml } from '@/utils/utils'
import Viewer from 'viewerjs'
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import store from '@/store'
import DOMPurify from 'dompurify'

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const richTextContent = ref(null)
    let viewer = null
    const viewerOptions = {
      toolbar: false,
      title: false,
      show() {
        console.log('show')
        if (window.location.hash !== '#vieweropen') {
          window.history.pushState(null, '', '#vieweropen')
        }
      },
      hide() {
        if (window.location.hash === '#vieweropen') {
          window.history.back()
        }
        console.log('hide')
      },
    }

    const sanitizedHTML = ref('')
    const setDom = () => {
      const clean = DOMPurify.sanitize(props.content)
      const parser = new DOMParser()
      const doc = parser.parseFromString(clean, 'text/html')
      const pathAssets = process.env.VUE_APP_PATH_ASSETS
      const qrDomain = store.getters.merchant?.base?.domain || '/'
      const domain = qrDomain + pathAssets
      doc.querySelectorAll('img').forEach((img) => {
        const imgSrc = img.getAttribute('src')
        if (!imgSrc.startsWith('http://') && !imgSrc.startsWith('https://')) {
          img.src = domain + imgSrc
        }
      })
      sanitizedHTML.value = doc.body.innerHTML
    }

    watch(
      () => props.content,
      () => {
        setDom()
        if (viewer) {
          viewer.destroy()
        }
        nextTick(() => {
          viewer = new Viewer(richTextContent.value, viewerOptions)
        })
      }
    )
    const hashchange = () => {
      if (window.location.hash !== '#vieweropen') {
        if (viewer) {
          viewer.hide()
        }
      }
    }
    onMounted(() => {
      nextTick(() => {
        viewer = new Viewer(richTextContent.value, viewerOptions)
      })
      window.addEventListener('hashchange', hashchange)
      setDom()
    })
    onUnmounted(() => {
      if (viewer) {
        viewer.destroy()
      }
      window.removeEventListener('hashchange', hashchange)
    })
    return {
      richTextContent,
      sanitizedHTML,
      onClickHtml,
    }
  },
}
</script>
<style>
.rich-text-content img {
  max-width: 100%;
  height: auto;
  cursor: zoom-in;
}
.rich-text-content video {
  max-width: 100%;
  height: auto;
}
.rich-text-content a {
  color: #1cb3d3;
}
</style>
