export const m = {
  // 繁体中文语言包
  app: {
    login: '登錄',
    'Home': '首頁',
    'Nearby': '周邊',
    'Message': '消息',
    'Mypage': '我的',
  },
  common: {
    // 暂无数据
    'NoData': '暫無數據',
    // 敬请期待
    'ComingSoon': '敬請期待',
    // 请重新登录
    'PleaseLoginAgain': '請重新登錄',
    // 登录已过期，请重新登录
    'LoginExpiredPleaseLoginAgain': '登錄已過期，請重新登錄',
    // 请在小程序中打开
    'PleaseOpenInMiniProgram': '請在小程序中打開',
    // 复制成功
    'copySuccess': '複製成功',
    // 复制失败
    'copyFail': '複製失敗',
  },
  home: {
    'readMore': '閱讀更多',
    'hotelEventOverview': '雪场活動概覽',
    // 暂无公告
    'NoAnnouncement': '暫無公告',
    // 取消
    'Cancel': '取消',
    // 滑雪场介绍
    'SkiResortIntroduction': '滑雪場介紹',
    // 营业时间
    'BusinessHours': '營業時間',
    // 已收藏
    'Collected': '已收藏',
    // 已取消收藏
    'UnCollected': '已取消收藏',
    // 您已成功收藏雪场【${merchantName}】\n请在【我的】->【我的收藏】里查看收藏列表
    'CollectedSuccessfully': '您已成功收藏雪场【{merchantName}】\n請在【我的】->【我的收藏】裡查看收藏列表',
    // 您已成功取消对雪场【${merchantName}】的收藏
    'UnCollectedSuccessfully': '您已成功取消對雪场【{merchantName}】的收藏',
  },
  accountHome: {
    welcome: '歡迎 🎉',
    Login: '登錄',
    Register: '注冊',
    ContinueAsAGuest: '以遊客身份繼續',
  },
  login: {
    'WelcomeBackGladToSeeYouAgain': '歡迎回來！很高興再次見到你！',
    // Enter your email
    'EnterYourEmail': '請輸入您的電子郵件',
    // Enter your password
    'EnterYourPassword': '請輸入您的密碼',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '請輸入正確的郵箱地址',
    // Forgot Password?
    'ForgotPassword': '忘記密碼？',
    // Don’t have an account?
    'DontHaveAnAccount': '沒有帳號？',
    // Register Now
    'RegisterNow': '現在註冊',
    // Login
    'Login': '登錄',
  },
  register: {
    'WelcomeBackGladToSeeYouAgain': '歡迎回來！很高興再次見到你！',
    // Nickname
    'Nickname': '昵稱',
    // Email
    'Email': '請輸入您的電子郵件',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '請輸入正確的郵箱地址',
    // Password
    'Password': '請輸入您的密碼',
    // 两次输入的密码不一致
    'PasswordsAreInconsistent': '兩次輸入的密碼不一致',
    // Confirm password
    'ConfirmPassword': '確認密碼',
    // Agree and Register
    'AgreeAndRegister': '註冊',
    // 发送验证码
    'SendCode': '發送驗證碼',
    // 验证
    'Verify': '驗證',
    // 注册用户
    'RegisteredUsers': '註冊成為新用戶',
    // OTP Verification
    'OTPVerification': '驗證碼校驗',
    // Enter the verification code we just sent on your email address.
    'EnterTheVerificationCodeWeJustSentOnYourEmailAddress': '請輸入我們剛剛發送到您電子郵件地址的驗證碼。',
    // 请填写密码
    'PleaseEnterYourPassword': '請填寫密碼',
    // 将会作为您的登录密码
    'WillBeUsedAsYourLoginPassword': '將會作為您的登錄密碼',
    // RegisteredSuccessfully
    'RegisteredSuccessfully': '註冊成功',
    // 请输入6-20位包含英数字的密码
    'PleaseEnterAPasswordOf6To20DigitsContainingEnglishAndNumbers': '請輸入6-20位包含英數字的密碼',
  },
  sso: {
    // Or Login with
    'OrLoginWith': '或登錄',
  },
  forgotPassword: {
    // Forgot Password
    'ForgotPassword': '忘記密碼？',
    // Don't worry! It occurs. Please enter the email address linked with your account.
    'DontWorryItOccursPleaseEnterTheEmailAddressLinkedWithYourAccount': '別擔心！ 請輸入與您的帳戶關聯的電子郵件地址。',
    // Enter your email
    'EnterYourEmail': '請輸入您的電子郵件',
    // Send Code
    'SendCode': '發送驗證碼',
    // Remember Password?
    'RememberPassword': '記得密碼？',
    // Login
    'Login': '登錄',
    // OTP Verification
    'OTPVerification': '驗證碼校驗',
    // Enter the verification code we just sent on your email address.
    'EnterTheVerificationCodeWeJustSentOnYourEmailAddress': '請輸入我們剛剛發送到您電子郵件地址的驗證碼。',
    // Verify
    'Verify': '驗證',
    // Didn’t received code?
    'DidntReceivedCode': '沒有收到驗證碼？',
    // Resend
    'Resend': '重新發送',
    // Create new password
    'CreateNewPassword': '創建新密碼',
    // Your new password must be unique from those previously used.
    'YourNewPasswordMustBeUniqueFromThosePreviouslyUsed': '您的新密碼必須與以前使用的密碼不同。',
    // New Password
    'NewPassword': '新密碼',
    // Confirm Password
    'ConfirmPassword': '確認密碼',
    // Reset Password
    'ResetPassword': '重置密碼',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '請輸入正確的郵箱地址',
    // 请输入密码
    'PleaseEnterYourPassword': '請輸入密碼',
    // 两次输入的密码不一致
    'PasswordsAreInconsistent': '兩次輸入的密碼不一致',
    // 发送成功
    'SendSuccessfully': '發送成功',
    // 请输入6-20位包含英数字的密码
    'PleaseEnterAPasswordOf6To20DigitsContainingEnglishAndNumbers': '請輸入6-20位包含英數字的密碼',
  },
  passwordChanged: {
    // Password Changed!
    'PasswordChanged': '密碼已更改！',
    // Your password has been changed successfully.
    'YourPasswordHasBeenChangedSuccessfully': '您的密碼已成功更改。',
    // Back to Login
    'BackToLogin': '返回登錄',
  },
  FeedBack: {
    // Name
    'Name': '姓名',
    // Please enter the English name on the passport!
    'PleaseEnterTheEnglishNameOnThePassport': '請輸入護照上的英文名！',
    // Telphone
    'Telphone': '電話號碼',
    // lease enter your phone number
    'LeaseEnterYourPhoneNumber': '請輸入您的電話號碼',
    // Email
    'Email': '電子郵件',
    // Please enter your e-mail address
    'PleaseEnterYourEMailAddress': '請輸入您的電子郵件地址',
    // 请输入正确的邮箱地址
    'PleaseEnterTheCorrectEmailAddress': '請輸入正確的郵箱地址',
    // Check-in 
    'CheckIn': '入住日期',
    // Please select a check-in date
    'PleaseSelectACheckInDate': '請選擇入住日期',
    // Feedback content
    'FeedbackContent': '反饋內容',
    // 请输入反馈内容
    'PleaseEnterYourFeedbackContent': '請輸入反饋內容',
    // 提交成功
    'SubmittedSuccessfully': '提交成功',
    // Please enter your comments and suggestions, and you can also contact the ski resort through here if the item is lost.
    'PleaseEnterYourCommentsAndSuggestionsAndYouCanAlsoContactTheHotelThroughHereIfTheItemIsLost': '請輸入您的意見和建議，如果物品丟失，您也可以通過此處與雪场聯繫。',
    // Sent
    'Sent': '發送',
  },
  navigation: {
    // Save to album
    'SaveToAlbum': '保存',
    // Navigate to the ski resort
    'NavigateToTheHotel': '導航到雪场',
    // ※可长按保存
    'LongPressToSave': '※可長按保存',
    // 地铁路线
    'SubwayRoute': '地鐵路線',
  },
  CouponFormat: {
    // See details
    'SeeDetails': '查看詳情',
    // Get
    'Get': '領取',
    // Use
    'Use': '使用',
    // 永久有效
    'PermanentValidity': '永久有效',
    // 有效期至：${item.endTimeDisplay}
    'ValidUntil': '有效期至：{endTimeDisplay}',
    // 有效天数：${item.effeDays}天
    'ValidDays': '有效天數：{effeDays}天',
    // 结束时间：${item.endTimeDisplay}
    'EndTime': '結束時間：{endTimeDisplay}',
    // 使用时间：${item.useTimeDisplay}
    'UseTime': '使用時間：{useTimeDisplay}',
    // `每人限领：${item.limit}次`
    'Limit': '每人限領：{limit}次',
  },
  shopList: {
    // JPY
    'JPY': 'JPY',
    // RMB
    'RMB': 'RMB',
  },
  barCart: {
    // JPY
    'JPY': 'JPY',
    // Next
    'Next': '去結算',
  },
  userCenter: {
    // Coupon
    'Coupon': '優惠券',
    // Membership
    'Membership': '雪场會員',
    // Collection
    'Collection': '收藏',
    // My Order
    'MyOrder': '我的訂單',
    // Coupon Center
    'CouponCenter': '優惠券中心',
    // Settings
    'Settings': '設置',
    // Service
    'Service': '服務',
    // Product review
    'ProductReview': '商品評價',
    // About QR SKI
    'AboutQRSKI': '關於QR SKI',
    // Logout
    'Logout': '退出登錄',
    // 我的收藏
    'MyCollection': '我的收藏',
    // 我的优惠券
    'MyCoupon': '我的優惠券',
  },
  eventList: {
    // 查看详情
    'SeeDetails': '查看詳情',
  },
  collectionList: {
    // 访问足迹
    'AccessFootprint': '訪問足跡',
    // 我的收藏
    'MyCollection': '我的收藏',
    // 点击查看〉
    'ClickToView': '點擊查看〉',
    // 确认
    'Confirm': '確認',
    // 确定要跳转吗？
    'ConfirmToJump': '確定要跳轉嗎？',
  },
  myCouponList: {
    // 可使用
    'Available': '可使用',
    // 已过期
    'Expired': '已過期',
    // 已使用
    'Used': '已使用',
    // 没有更多啦~
    'NoMore': '沒有更多啦~',
    // 即将过期
    'Expiring': '即將過期',
    // 去使用
    'GoToUse': '去使用',
    // 领取
    'Get': '領取',
  },
  NotFound: {
    // 信息获取失败
    'HotelInformationFailedToGet': '信息獲取失敗',
    // 查看介绍
    'ViewIntroduction': '查看介紹',
  },
  NoOpen: {
    // 该雪场暂未开放
    'NoOpen': '該雪场暫未開放',
    // 查看介绍
    'ViewIntroduction': '查看介紹',
  },
  Article: {
    // 营业时间
    'BusinessHours': '營業時間',
    // 介绍
    'Introduction': '介紹',
  },
  DiscoverDetail: {
    // 问路卡
    'AskForTheWayCard': '問路卡',
    // 导航
    'Navigation': '導航',
    // 简介
    'Introduction': '簡介',
    // 基本信息
    'BasicInformation': '基本信息',
    // 地址
    'Address': '地址',
    // 营业时间
    'BusinessHours': '營業時間',
    // 本页信息由第三方来源提供，不保证信息及时有效
    'FooterTip': '本頁信息由第三方來源提供，不保證信息及時有效',
    // （人均消费）
    'AverageConsumption': '（人均消費）',
  },
  Coupon: {
    // 商家专属
    'MerchantExclusive': '商家專屬',
    // 平台通用
    'PlatformGeneric': '平台通用',
    // 10%免税
    '10DutyFree': '10%免稅',
    // 特定店铺
    'SpecificStore': '特定店鋪',
    // 去使用
    'GoToUse': '去使用',
    // 去领取
    'GoToGet': '去領取',
    // 去查看
    'GoToView': '去查看',
  },
  CouponDetail: {
    // 10%免税
    '10DutyFree': '10%免稅',
    // 优惠券详情
    'CouponDetail': '優惠券詳情',
    // 特定店铺
    'SpecificStore': '特定店鋪',
    // 使用方法
    'UseMethod': '使用方法',
    // 使用须知
    'UseNotice': '使用須知',
    // 本优惠券为第三方搜集，进店时请与店员做好沟通，本程序不承担连带责任
    'Disclaimer': '本優惠券為第三方搜集，進店時請與店員做好溝通，本程序不承擔連帶責任',
    // 已过期
    'Expired': '已過期',
    // 已使用
    'Used': '已使用',
    // 请让雪场服务人员点击，自己点击无效，敬请谅解
    'PleaseLetTheHotelStaffClickItYourself': '請讓雪场服務人員點擊，自己點擊無效，敬請諒解',
    // 由服务人员使用
    'UsedByTheServiceStaff': '由服務人員使用',
    // 领取
    'Get': '領取',
    // 确认
    'Confirm': '確認',
    // 确认要使用此优惠券吗？
    'ConfirmToUseThisCoupon': '確認要使用此優惠券嗎？',
    // 请登录
    'PleaseLogin': '請登錄',
  },
  Cropper: {
    // 裁切
    'Crop': '裁切',
  },
  UserSetting: {
    // 请输入昵称
    'PleaseEnterYourNickname': '請輸入昵稱',
    // 手机号
    'PhoneNumber': '手機號',
    // 请输入您的手机号
    'PleaseEnterYourPhoneNumber': '請輸入您的手機號',
    // 保存
    'Save': '保存',
  },
  AiChat: {
    // 觉得答案如何？
    'HowDoYouFeelAboutTheAnswer': '覺得答案如何？',
    // 感谢您的回馈！
    'ThankYouForYourFeedback': '感謝您的回饋！',
    // 按住 说话
    'PressAndSpeak': '按住 說話',
    // 发送
    'Send': '發送',
    // 松开 结束
    'ReleaseEnd': '鬆開 結束',
    // 最多支持${queryLimit.value}个字的发送
    'MaximumSupportForSendingWords': '最多支持{queryLimit}個字的發送',
    // 连接发生错误
    'ConnectionError': '連接發生錯誤',
    // 你好，我是${res.data.name}的小助手
    'HelloIAmTheAssistant': '你好，我是{name}的小助手',
    // ${merchatData.name}的AI小助手为您服务
    'AIAssistantForMerchat': '{name}的AI小助手為您服務',
    // 无法查询到设施信息，请重新扫码
    'UnableToQueryFacilityInformationPleaseScanAgain': '無法查詢到設施信息，請重新掃碼',
    // 该设施已暂停咨询服务，请重新扫码
    'TheFacilityHasSuspendedConsultingServicesPleaseScanAgain': '該設施已暫停咨詢服務，請重新掃碼',
    // 查询设施信息失败，请稍后再试
    'FailedToQueryFacilityInformationPleaseTryAgainLater': '查詢設施信息失敗，請稍後再試',
    // 以下是一些常见问题，请点击您想问的问题，确认答案。
    'TheFollowingAreSomeCommonQuestionsPleaseClickOnTheQuestionYouWantToAskToConfirmTheAnswer': '以下是一些常見問題，請點擊您想問的問題，確認答案。',
    // 语音识别初始化失败，请检查麦克风权限
    'VoiceRecognitionInitializationFailedPleaseCheckTheMicrophonePermission': '語音識別初始化失敗，請檢查麥克風權限',
    // 很抱歉，可以更详细的描述您的问题吗？
    'SorryCanYouDescribeYourProblemInMoreDetail': '很抱歉，可以更詳細的描述您的問題嗎？',
  },
  TranslationHelper: {
    // 说中文
    'SayLang': '中文',
    // 说日语
    'SayJp': '日語',
    // 说英语
    'SayEn': '英語',
    // 按住 说话
    'PressAndSpeak': '按住 說話',
    // 松开 结束
    'ReleaseEnd': '鬆開 結束',
    // 语音识别初始化失败，请检查麦克风权限
    'VoiceRecognitionInitializationFailedPleaseCheckTheMicrophonePermission': '語音識別初始化失敗，請檢查麥克風權限',
    // 通过下方工具栏，输入文字或语音，即可进行翻译
    'EnterTextOrVoice': '通過下方工具欄，輸入文字或語音，即可進行翻譯',
    // 发送过于频繁，请稍后再试
    'SendTooFrequentlyPleaseTryAgainLater': '發送過於頻繁，請稍後再試',
    // 连接失败，请稍后重试
    'ConnectionFailedPleaseTryAgainLater': '連接失敗，請稍後重試',
  },
  gelande: {
    // 全部区域
    'AllAreas': '全部區域',
    // 全部状况
    'AllConditions': '全部狀況',
  },
  ErrorPage: {
    // 该内容不支持当前语言
    '406': '該內容不支持当前語言',
    // 发生错误，请稍后再试
    '500': '發生錯誤，請稍後再試',
  }
};