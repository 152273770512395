import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import { setLastRoute, getLastRoute } from '@/utils/utils'

const routes = [
  {
    path: '/',
    name: 'CheckMerchantId',
    component: () => import(/* webpackChunkName: "CheckMerchantId" */ '../views/checkMerchantId/CheckMerchantId.vue'),
  },
  // Index
  {
    path: '/:id',
    name: 'MerchantIndex',
    component: () => import(/* webpackChunkName: "Index" */ '../views/merchant/Index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/merchant/home/Home.vue'),
        meta: {
          showTopBar: true,
        }
      },
      {
        // discover
        path: 'discover/index',
        name: 'Discover',
        component: () => import(/* webpackChunkName: "Discover" */ '../views/merchant/discover/Discover.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // DiscoverList
      {
        path: 'discover/list/:categoryid/:isCustom?',
        name: 'DiscoverList',
        component: () => import(/* webpackChunkName: "DiscoverList" */ '../views/merchant/discover/DiscoverList.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // DiscoverListFood
      {
        path: 'discover/list/food',
        name: 'DiscoverListFood',
        component: () => import(/* webpackChunkName: "DiscoverListFood" */ '../views/merchant/discover/DiscoverListFood.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // DiscoverDetail
      {
        path: 'discover/:categoryid/detail/:discoverid/:isCustom?',
        name: 'DiscoverDetail',
        component: () => import(/* webpackChunkName: "DiscoverDetail" */ '../views/merchant/discover/DiscoverDetail.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // DiscoverDetailFood
      {
        path: 'discover/food/detail/:discoverid',
        name: 'DiscoverDetailFood',
        component: () => import(/* webpackChunkName: "DiscoverDetailFood" */ '../views/merchant/discover/DiscoverDetailFood.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // Article
      {
        path: 'article/:articleid',
        name: 'Article',
        component: () => import(/* webpackChunkName: "Article" */ '../views/merchant/Article/Article.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // index/hotel/hotelDetail
      {
        path: 'index/hotel/hoteldetail',
        name: 'WxHotelDetail',
        pathToRegexpOptions: { sensitive: false },
        meta: {
          showTopBar: true,
        },
        beforeEnter: (to, from, next) => {
          next({ name: 'Article', params: { articleid: to.query?.id } });
        }
      },
      // Article
      {
        path: 'about/merchant',
        name: 'AboutHotel',
        component: () => import(/* webpackChunkName: "Article" */ '../views/merchant/Article/Article.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // Faq
      {
        path: 'faq',
        name: 'Faq',
        component: () => import(/* webpackChunkName: "Faq" */ '../views/merchant/faq/Faq.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // FeedBack
      {
        path: 'message/add/index',
        // 别名
        alias: 'index/feedback/feedback',
        name: 'FeedBack',
        component: () => import(/* webpackChunkName: "FeedBack" */ '../views/merchant/feedback/FeedBack.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // Navigation
      {
        path: 'map/index',
        // 别名
        alias: 'index/backnavi/backnavi',
        name: 'Navigation',
        component: () => import(/* webpackChunkName: "Navigation" */ '../views/merchant/navigation/Navigation.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // Coupon
      {
        path: 'coupon/index',
        // 别名
        alias: 'profile/couponlist/couponlist',
        name: 'Coupon',
        component: () => import(/* webpackChunkName: "Coupon" */ '../views/merchant/coupon/Coupon.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // CouponDetail
      {
        path: 'coupon/detail/:couponid',
        name: 'CouponDetail',
        component: () => import(/* webpackChunkName: "CouponDetail" */ '../views/merchant/coupon/CouponDetail.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // ShopList
      {
        path: 'shop/list',
        name: 'ShopList',
        component: () => import(/* webpackChunkName: "ShopList" */ '../views/merchant/shop/ShopList.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // gelande/course/course.vue
      // {
      //   path: 'gelande/course',
      //   name: 'GelandeCourse',
      //   // 别名
      //   alias: 'gelande/course/course',
      //   component: () => import(/* webpackChunkName: "GelandeCourse" */ '../views/merchant/gelande/course/Course.vue'),
      //   meta: {
      //     showTopBar: true,
      //   }
      // },
      // gelande/lift/Lift.vue
      // {
      //   path: 'gelande/lift',
      //   name: 'GelandeLift',
      //   // 别名
      //   alias: 'gelande/lift/lift',
      //   component: () => import(/* webpackChunkName: "GelandeLift" */ '../views/merchant/gelande/lift/Lift.vue'),
      //   meta: {
      //     showTopBar: true,
      //   }
      // },
      // HotelServiceList
      {
        path: 'merchant/service/list',
        name: 'HotelServiceList',
        component: () => import(/* webpackChunkName: "HotelServiceList" */ '../views/merchant/hotel/HotelServiceList.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // UserCenter
      {
        path: 'profile/index',
        name: 'UserCenter',
        component: () => import(/* webpackChunkName: "UserCenter" */ '../views/merchant/user/UserCenter.vue'),
        meta: {
          showTopBar: true,
          shouldLogin: true,
        }
      },
      // UserSetting
      {
        path: 'profile/setting',
        name: 'UserSetting',
        component: () => import(/* webpackChunkName: "UserSetting" */ '../views/merchant/user/UserSetting.vue'),
        meta: {
          showTopBar: true,
          shouldLogin: true,
        }
      },
      // EventList
      {
        path: 'event/list/:articleid',
        name: 'EventList',
        component: () => import(/* webpackChunkName: "EventList" */ '../views/merchant/event/EventList.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // CollectionList
      {
        path: 'collection/list',
        name: 'CollectionList',
        component: () => import(/* webpackChunkName: "CollectionList" */ '../views/merchant/collection/CollectionList.vue'),
        meta: {
          showTopBar: true,
          shouldLogin: true,
        }
      },
      // myCouponList
      {
        path: 'my/coupon/list',
        name: 'MyCouponList',
        component: () => import(/* webpackChunkName: "MyCouponList" */ '../views/merchant/myCoupon/MyCouponList.vue'),
        meta: {
          showTopBar: true,
          shouldLogin: true,
        }
      },
      {
        path: 'my/coupon/detail/:merchantid/:couponid',
        name: 'MyCouponDetail',
        component: () => import(/* webpackChunkName: "CouponDetail" */ '../views/merchant/coupon/CouponDetail.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // ContentDetail.vue
      {
        path: 'content/content',
        // 别名
        alias: 'index/content/content',
        name: 'ContentDetail',
        component: () => import(/* webpackChunkName: "ContentDetail" */ '../views/merchant/content/ContentDetail.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // translationHelper
      {
        path: 'translation/helper',
        name: 'TranslationHelper',
        component: () => import(/* webpackChunkName: "TranslationHelper" */ '../views/merchant/translationHelper/TranslationHelper.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // AiChat
      {
        path: 'ai/chat/:merchantid',
        name: 'AiChat',
        component: () => import(/* webpackChunkName: "AiChat" */ '../views/merchant/aiChat/AiChat.vue'),
        meta: {
          showTopBar: true,
        }
      },
      // Error
      {
        path: 'error/:code',
        name: 'Error',
        component: () => import(/* webpackChunkName: "Error" */ '../views/merchant/error/Error.vue'),
        meta: {
          showTopBar: true,
          shouldLogin: false,
        }
      },
      // account/AccountHome
      {
        path: 'account',
        name: 'AccountHome',
        component: () => import(/* webpackChunkName: "AccountHome" */ '../views/account/AccountHome.vue'),
        meta: {
          showTopBar: false,
        }
      },
      // account/login
      {
        path: 'account/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/account/Login.vue'),
        meta: {
          showTopBar: false,
        }
      },
      // account/register
      {
        path: 'account/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "Register" */ '../views/account/Register.vue'),
        meta: {
          showTopBar: false,
        }
      },
      // account/forget
      {
        path: 'account/forget',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '../views/account/ForgotPassword.vue'),
        meta: {
          showTopBar: false,
        }
      },
      // account/PasswordChanged
      {
        path: 'account/password-changed',
        name: 'PasswordChanged',
        component: () => import(/* webpackChunkName: "PasswordChanged" */ '../views/account/PasswordChanged.vue'),
        meta: {
          showTopBar: false,
        }
      },
      // about/about
      {
        // 重定向到About
        path: 'about/about',
        redirect: '/about/about',
      },

    ]
  },
  // ComingSoon
  {
    path: '/coming-soon',
    name: 'ComingSoon',
    component: () => import(/* webpackChunkName: "ComingSoon" */ '../views/comingSoon/ComingSoon.vue'),
  },

  // 404
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/notFound/NotFound.vue'),
    meta: {
      showTopBar: false,
    }
  },
  {
    path: '/error/:code',
    name: 'ErrorNoMerchantId',
    component: () => import(/* webpackChunkName: "Error" */ '../views/merchant/error/Error.vue'),
    meta: {
      showTopBar: false,
    }
  },
  // NoOpen
  {
    path: '/no-open',
    name: 'NoOpen',
    component: () => import(/* webpackChunkName: "NoOpen" */ '../views/noOpen/NoOpen.vue'),
    meta: {
      showTopBar: false,
    }
  },
  // About
  {
    path: '/about',
    alias: '/about/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '../views/about/About.vue'),
    meta: {
      showTopBar: false,
    }
  },
  // 不存在的路由跳转到404
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found',
    meta: {
      showTopBar: false,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const shouldLogin = to.meta.shouldLogin;
  const token = store.getters.token;
  const id = to.params.id;
  if (shouldLogin && !token) {
    setLastRoute(to);
    next({ name: "Login", params: { id } });
    return;
  }
  next();
});

router.afterEach((to, from) => {
  console.log("afterEach");
});


export default router
